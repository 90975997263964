import { useState, useCallback, useEffect } from 'react';
import { Modal, CloseButton } from 'react-bootstrap';

// import { Modal, CloseButton } from 'react-bootstrap';

//carousel
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';

require('isomorphic-fetch');
const Dropbox = require('dropbox').Dropbox;

function DropboxCarousel(props) {

    const [showImage, setShowImage] = useState(false);
    const [image, setImage] = useState('');

    // const [imageArray, setImageArray] = useState([]);

    // const [show] = useState(4);
    // const [infiniteLoop] = useState(true);

    // const [currentIndex, setCurrentIndex] = useState(0);
    // const [length, setLength] = useState(0);

    // const [isRepeating, setIsRepeating] = useState(infiniteLoop && imageArray.length > show);
    // const [transitionEnabled, setTransitionEnabled] = useState(true);

    // const [touchPosition, setTouchPosition] = useState(null);

    const [thumbnailArray, setThumbnailArray] = useState([]);

    // var form = document.getElementById('basic-form');

    const listFiles = useCallback(async () => {
        // e.preventDefault();
        const dbx = new Dropbox({ accessToken: process.env.REACT_APP_ACCESS_TOKEN });

        if (thumbnailArray[0]) return;

        // dbx.usersGetCurrentAccount()
        //     .then(function (response) {
        //         console.log(response);
        //     })
        //     .catch(function (error) {
        //         console.error(error);
        //     });
        await dbx.filesListFolder({ path: '' })
            .then(function (response) {
                // setLength(response.result.entries.length)
                // setIsRepeating(infiniteLoop && response.result.entries.length > show)
                getImageLinks(response.result.entries);
            })
            .catch(function (error) {
                console.error(error);
            });
    }, [thumbnailArray])

    async function getImageLinks(entries) {
        let dbx = new Dropbox({ accessToken: process.env.REACT_APP_ACCESS_TOKEN });
        // let linkArray = [];
        let thumbArray = [];
        await entries.map(async (file, i) => {
            await dbx.filesGetThumbnail({ path: file.path_display, size: 'w960h640' })
                .then(function (response) {
                    thumbArray.push({
                        src: response.result.fileBlob,
                        name: response.result.name
                    })
                })
                .catch(function (error) {
                    console.error(error)
                })
            // await dbx.filesGetTemporaryLink({ path: file.path_display })
            //     .then(function (response) {
            //         linkArray.push({
            //             src: response.result.link,
            //             name: response.result.metadata.name
            //         })
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //         return null;
            //     })
            if (i === entries.length - 1) {
                // setImageArray(linkArray);
                return setThumbnailArray(thumbArray);
            }
            return null;
        })
    }

    const returnHex = () => {
        return thumbnailArray.map((image, i) => {
            return (
                <li class="hex">
                    <div class="hexIn">
                        <div class="hexLink" onClick={() => handleShowImage(image)}>
                            <img src={URL.createObjectURL(image.src)} alt={image.name} />
                            {/* <h1>This is a title</h1>
                            <p>Some sample text about the article this hexagon leads to</p> */}
                        </div>
                    </div>
                </li>
            )
        })
    }

    const handleShowImage = (image = '') => {
        if (showImage) {
            setImage('');
        } else {
            setImage(image);
        }
        setShowImage(!showImage);
    }

    useEffect(() => {
        listFiles()
    }, [listFiles])

    return (
        <div>
            <div className='hex-container' id="hex-container">
                <ul id="hexGrid">
                    {returnHex()}
                </ul>
            </div>

            {/* <div className="carousel-container">
                <div
                    className="carousel-wrapper"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                > */}
            {/* You can alwas change the content of the button to other things */}
            {
                // (isRepeating || currentIndex > 0) &&
                // <button onClick={prev} className="left-arrow">
                //     &lt;
                // </button>
            }
            {/* <div
                        className="carousel-content-wrapper"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                    >
                        <div
                            className={`carousel-content show-${show}`}
                            style={{
                                transform: `translateX(-${currentIndex * (100 / show)}%)`,
                                transition: !transitionEnabled ? 'none' : undefined,
                            }}
                            onTransitionEnd={() => handleTransitionEnd()}
                        > */}
            {
                // (length > show && isRepeating) &&
                // renderExtraPrev()
            }
            {/* {displayThumbnails()} */}
            {
                // (length > show && isRepeating) &&
                // renderExtraNext()
            }
            {/* </div>
                    </div> */}
            {/* You can alwas change the content of the button to other things */}
            {
                // (isRepeating || currentIndex < (length - show)) &&
                // <button onClick={next} className="right-arrow">
                //     &gt;
                // </button>
            }
            {/* </div>
            </div> */}
            <Modal
                size="lg"
                show={showImage}
                onHide={() => handleShowImage()}
                aria-labelledby="selected-image"
            >
                <Modal.Header >
                    {/* <Modal.Title id="example-modal-sizes-title-lg">
                        Dropbox
                    </Modal.Title> */}
                    <CloseButton variant="white" onClick={() => props.setShowDropbox(false)} />
                </Modal.Header>
                <Modal.Body>
                    {image ? <img style={{ width: '100%' }} src={URL.createObjectURL(image.src)} alt={image.name} /> : ''}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DropboxCarousel;