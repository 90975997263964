import { useState } from 'react';

import { Modal, Row, Col, CloseButton } from 'react-bootstrap';
import SocialMediaLinks from '../social/socialMediaLinks';

import toddProfilePic from '../../assets/images/todd-profile-pic.jpg';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

function Contact(props) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", name, email, message })
        })
            .then(() => {
                setName('');
                setEmail('');
                setMessage('');
                alert("Success!");
            })
            .catch(error => alert(error));
        e.preventDefault();
    };

    return (
        <div>
            <Modal
                size="lg"
                show={props.showContact}
                onHide={() => props.setShowContact(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Contact Me
                    </Modal.Title>
                    <CloseButton variant="white" onClick={() => props.setShowContact(false)} />
                </Modal.Header>
                <Modal.Body>
                    <div className='contacts'>
                        <div className='contact row'>
                            <div className='col-md-9'>
                                <div className='contact-title'>
                                    <h2>Todd Harris</h2>
                                    <p>Sports Announcer</p>
                                </div>
                                <ul className='contact-info'>
                                    <li>Email: <a href="mailto:toddharris1@aol.com">toddharris1@aol.com</a></li>
                                </ul>
                            </div>
                            <div className='col-md-3'>
                                <img className='contact-img' src={toddProfilePic} alt="Todd Harris Portrait" />
                            </div>
                        </div>
                        {/* <div className='contact row'>
                            <div className='col-md-9'>
                                <div className='contact-Title'>
                                    <h2>Michael Spencer</h2>
                                    <p>Managing Director</p>
                                    <ul>
                                        <li>Number: <a href="tel:3108746978">(310)874-6978</a></li>
                                        <li>Email: <a href="mailto:mspencer@onesportsglobal.com">mspencer@onesportsglobal.com</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-md-3'> */}
                                {/* <img className='contact-img' src={} alt="Todd Harris Portrait" /> */}
                            {/* </div>
                        </div> */}
                    </div>
                    <form className="contact-form" onSubmit={handleSubmit}>
                        <input type="hidden" name="form-name" value="contact" />
                        <Row>
                            <Col xs={7}>
                                <div className="form-group mb-3" controlId="contactName">
                                    <label for="name">Name</label>
                                    <input className="form-control subtle-fields" value={name} name="name" type="name" placeholder="e.g. Michael Jordan" onChange={event => setName(event.target.value)} />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group mb-3" controlId="contactEmail">
                                    <label for="email">Email address</label>
                                    <input className="form-control subtle-fields" value={email} name="email" type="email" id="email" placeholder="Enter email" onChange={event => setEmail(event.target.value)} />
                                </div>
                            </Col>
                        </Row>
                        <div className="form-group mb-3" controlId="formBasicCheckbox">
                            <label for="name">Message</label>
                            <textarea className="form-control subtle-fields" name="message" value={message} style={{ height: '100px' }} onChange={event => setMessage(event.target.value)}></textarea>
                        </div>
                        <button className="btn btn-secondary" type="submit">
                            Submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <SocialMediaLinks />
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Contact;