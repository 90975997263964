function SocialMediaLinks() {



    return (
        <ul className="media-icons">
            <li><a href="https://www.linkedin.com/in/todd-harris-4556117/" className="icon fa-linkedin" target="_blank" rel="noreferrer"><span className="label">Facebook</span></a></li>
            <li><a href="https://twitter.com/TheToddHarris" className="icon fa-twitter" target="_blank" rel="noreferrer"><span className="label">Twitter</span></a></li>
            <li><a href="https://instagram.com/the.todd.harris" className="icon fa-instagram" target="_blank" rel="noreferrer"><span className="label">Instagram</span></a></li>
        </ul>
    )
}

export default SocialMediaLinks