import SocialMediaLinks from "../social/socialMediaLinks";

import './footer.scss';

function Footer() {

    return (
        <div className="footer">
            <SocialMediaLinks />
        </div>
    )
}

export default Footer;