import { Modal, CloseButton } from 'react-bootstrap';

import mma from '../../assets/images/mma.jpg';

function Experience(props) {

    const abcLink = 'https://upload.wikimedia.org/wikipedia/commons/5/54/American_Broadcasting_Company_Logo.svg';
    const espnLink = 'https://upload.wikimedia.org/wikipedia/commons/2/2f/ESPN_wordmark.svg';
    const tntLink = 'https://upload.wikimedia.org/wikipedia/commons/2/24/TNT_Logo_2016.svg';
    const nbcLink = 'https://upload.wikimedia.org/wikipedia/commons/8/8d/NBC_2013_%28flat_version%29.svg';

    return (
        <div>
            <Modal
                size="lg"
                show={props.showExperience}
                onHide={() => props.setShowExperience(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Experience
                    </Modal.Title>
                    <CloseButton variant="white" onClick={() => props.setShowExperience(false)} />
                </Modal.Header>
                <Modal.Body>
                    <div className="experience-image" style={{ backgroundImage: `url(${mma})`, backgroundPositionX: 'left', backgroundPositionY: 'top' }}></div>
                    <br />
                    <div className="logo-container center mb-3">
                        <span className="logo-image mx-1" style={{backgroundImage: `url(${abcLink})`}}>ABC</span>
                        <span className="logo-image" style={{backgroundImage: `url(${espnLink})`}}>ESPN</span>
                        <span className="logo-image" style={{backgroundImage: `url(${tntLink})`}}>TNT</span>
                        <span className="logo-image" style={{backgroundImage: `url(${nbcLink})`}}>NBC</span>
                    </div>
                    <p>My broadcasting career began after graduating from Brigham Young University with a degree in Communications/Broadcast Journalism. My first professional post was with WBKO NewsCenter 13, an ABC affiliate in Bowling Green Kentucky, where I served as a weekday reporter and weekend news anchor.</p>
                    <p>Following a few years in Kentucky, I was offered an opportunity to work with a production company out of Southern California, called DynoComm. They did production on action sports for ESPN and ESPN2 as well as few other cable channels like Prime Network. This job entailed covering sports such as; Surfing, Snowboarding and Skateboarding. My response was always, “ I can cover that”.</p>
                    <p>So began my foray into a long list of sports. One of the most interesting events to come my way was The Worlds Strongest Man World Championship. I maintained my position as lead announcer on this event for nearly 18 years. It took me around the world to places I never thought I would visit. It was produced by TWI/IMG, and was always a highlight of the year. I spent a few years doing events for Turner Sports and TNT which allowed me to work on NBA coverage as a sideline reporter, as well as the NBA Playoffs for several years. It also included 2 very memorable Goodwill Games, and my first Olympics in Nagano Japan. My exposure and assignments continued primarily on ESPN and subsequently led to an offer from ABC Sports to be the sideline reporter for the ABC college football game of the week in the Pac 10. I was paired with Legendary broadcaster, Keith Jackson and NFL Hall of Famer, Dan Fouts. Some of the best broadcasts and most memorable years of my career so far.</p>
                    <p>After several more years with ABC/ESPN, I received an offer from NBC Sports to be a part of their action sports lineup and the chance to host the Summer and Winter Dew Tour as well as being a part of their Olympic Coverage. My role at NBC turned into a full time position after the 2010 Olympics in Vancouver Canada, and now includes work on College Football and Basketball as well as the Red Bull Signature Series. The Tour de France, Swimming World Championships and Track and Field coverage soon followed. I just finished a year with 2 Olympic games less than 7 months apart. I covered the introduction of Skateboarding at the Tokyo Summer games as well as BMX and then Snowboarding and Free Ski events in Beijing for the Winter Olympics of 2022.</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Experience;