import './App.scss';

import Navigation from "./components/navigation/navigation";

import Main from './components/main';

import DropboxCarousel from './components/dropbox';
import Footer from './components/footer/footer';

function App() {
  return (
    <div className="App">
      <div className='app-section'>
        <Main />
        <div className="grey-underlay">
          <Navigation />
        </div>
        <DropboxCarousel />
        <Footer />
      </div>
    </div>
  );
}

export default App;
