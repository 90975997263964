import { useState } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

import About from '../about/about';
import Contact from '../contact/contact';
import Experience from '../experience/experience';
// import DropboxCarousel from './dropbox';

function Navigation (props) {

    const [showAbout, setShowAbout] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showExperience, setShowExperience] = useState(false);
    // const [showDropbox, setShowDropbox] = useState(false);

    return (
        <div className="tabs-container">
            <ButtonGroup aria-label='navigations'>
                <Button size="lg" variant="outline-light" onClick={() => setShowAbout(true)}>About</Button>
                <Button size="lg" variant="outline-light" onClick={() => setShowExperience(true)}>Experience</Button>
                <Button size="lg" variant="outline-light" onClick={() => setShowContact(true)}>Contact</Button>
                {/* <Button size="lg" variant="outline-light" onClick={() => setShowDropbox(true)}>Images</Button> */}
            </ButtonGroup>
            <About showAbout={showAbout} setShowAbout={setShowAbout} />
            <Contact showContact={showContact} setShowContact={setShowContact} />
            <Experience showExperience={showExperience} setShowExperience={setShowExperience} />
            {/* <DropboxCarousel showDropbox={showDropbox} setShowDropbox={setShowDropbox} /> */}
        </div>
    )
}

export default Navigation;