import { Modal, CloseButton } from 'react-bootstrap';

import olympics from '../../assets/images/olympics.jpg';

function About(props) {

    return (
        <div>
            <Modal
                size="lg"
                show={props.showAbout}
                onHide={() => props.setShowAbout(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header >
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Todd Harris
                    </Modal.Title>
                    <CloseButton variant="white" onClick={() => props.setShowAbout(false)} />
                </Modal.Header>
                <Modal.Body>
                    <div className="about-image" style={{ backgroundImage: `url(${olympics})`, backgroundPositionX: 'left', backgroundPositionY: 'top' }}></div>
                    <br />
                    <p>Todd Harris is an award winning on-air sports journalist with over 3 decades of experience across a long list of sports, covering for multiple networks. Current assignments include; Lead commentator for Sail GP, and continuing coverage for the US Ski and Snowboard teams season from Grand Prixs' to World Cup events as well as the Winter/Summer Dew Tour.</p>
                    <p>Harris will begin his third year with the PAC12 Network as a play by play announcer. With 9 Olympic games, 7 Rose Bowl Games, 5 Tour de Frances’, 3 College Football National Championship Games, 2 Indy 500’s, 2 Americas Cups and countless other events, He is as experienced as he is versatile.</p>
                    <p>An accomplished show host, voice over artist and play by play announcer, Todd Harris is always looking for a new challenge. If you have a project that you think he might be right for, please reach out to discuss.</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default About;