
function Main() {

    return (
        <div className="main">
            {/* <div className="overlay" id="main-overlay"></div> */}
            <div className="content">
                <div className='row align-items-center'>
                    <div className='col-md-4 offset-md-1'>
                        <div className="title-container">
                            <h1 className="main-title">Todd Harris</h1>
                            <p className="main-sub-title">Professional Sports Announcer</p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="video-container">
                            <video className="highlight-video" controls autoPlay muted playsInline loop controlsList="nodownload">
                                <source src="https://dl.dropboxusercontent.com/s/onrkz6vgep6ttk2/todd_harris_intro_highlights.mp4?dl=0&rel=0&start" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture; autoplay" allowfullscreen />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;